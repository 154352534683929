* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

* {
  word-break: inherit !important;
  overflow-wrap: anywhere !important;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

b, strong {
  color: #485ce6 !important;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Variable.woff2") format("woff2"), url("../fonts/Satoshi-Variable.woff") format("woff"), url("../fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-VariableItalic.woff2") format("woff2"), url("../fonts/Satoshi-VariableItalic.woff") format("woff"), url("../fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Light.woff2") format("woff2"), url("../fonts/Satoshi-Light.woff") format("woff"), url("../fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-LightItalic.woff2") format("woff2"), url("../fonts/Satoshi-LightItalic.woff") format("woff"), url("../fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Regular.woff2") format("woff2"), url("../fonts/Satoshi-Regular.woff") format("woff"), url("../fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Italic.woff2") format("woff2"), url("../fonts/Satoshi-Italic.woff") format("woff"), url("../fonts/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Medium.woff2") format("woff2"), url("../fonts/Satoshi-Medium.woff") format("woff"), url("../fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-MediumItalic.woff2") format("woff2"), url("../fonts/Satoshi-MediumItalic.woff") format("woff"), url("../fonts/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Bold.woff2") format("woff2"), url("../fonts/Satoshi-Bold.woff") format("woff"), url("../fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-BoldItalic.woff2") format("woff2"), url("../fonts/Satoshi-BoldItalic.woff") format("woff"), url("../fonts/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Black.woff2") format("woff2"), url("../fonts/Satoshi-Black.woff") format("woff"), url("../fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-BlackItalic.woff2") format("woff2"), url("../fonts/Satoshi-BlackItalic.woff") format("woff"), url("../fonts/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
html {
  font-size: 62.5%;
}
@media (min-width: 700px) and (max-width: 1599px) {
  html {
    font-size: 62.5%;
  }
}

strong {
  color: #485ce6 !important;
}

.form-group {
  position: relative;
  margin: 0;
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

.reveal-text {
  visibility: hidden;
}

.line {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: start;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

/*
    SUMMARY
        0 - Set Transitions
        1 - Hovers
        2 - Enter Animations
        3 - Keyframes

*/
img.zoom--hover,
img.up--hover,
img.arrow--hover {
  transition: 0.4s;
}

.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

.header {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 0px;
  background: transparent linear-gradient(180deg, #000000 0%, rgba(1, 5, 15, 0) 100%) 0% 0% no-repeat padding-box;
  z-index: 1000;
  height: fit-content;
  transition: 1s;
  /* ONE */
}
.header.active {
  background: #485CE6 !important;
  left: inherit;
  right: 0;
  width: calc(100% - 25px);
  padding-bottom: 42px;
}
.header.active .header--logo {
  filter: brightness(0) invert(1);
}
.header.activeDown {
  background: #000008;
}
.header .arrow-seach {
  cursor: pointer;
}
.header .arrow-seach img {
  width: 20px;
  height: 20px;
  object-fit: fill;
}
.header--container {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
}
.header--logo {
  width: 192px;
  height: 24px;
  object-fit: contain;
}
.header .wrapper-dropdown {
  display: flex;
  flex-direction: column;
  gap: 21px;
}
.header .service-list {
  max-height: 80vh;
  min-width: 865px;
  gap: 100px;
  grid-template-columns: 300px 1fr;
  width: auto;
}
@media (max-height: 680px) {
  .header .service-list {
    padding: 25px;
  }
  .header .service-list .service-wrapper {
    gap: 5px;
  }
  .header .service-list .service-wrapper li a {
    font-size: 1.8rem;
  }
}
@media (max-width: 1500px) {
  .header .contato-dropdown:hover {
    transform: translate(-82%, 100%) !important;
  }
}
.header .service-wrapper {
  display: grid;
  grid-template-columns: 300px 300px;
  gap: 20px;
}
.header .wrapper-dropdonw li a {
  color: #fff !important;
}
.header .cta-header {
  display: flex;
  flex-direction: column;
}
.header .cta-header--title {
  font-size: 4.8rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
}
.header .cta-header--text {
  font-size: 1.6rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
  margin-bottom: 0;
  margin-top: 20px;
}
@media (max-width: 1599px) {
  .header .cta-header--text {
    font-size: 2rem;
  }
}
.header .cta-header--link {
  font-size: 2rem;
  line-height: 1;
  color: #485CE6 !important;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: center;
  text-decoration: none;
  padding: 20px 40px;
  margin-top: 44px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  gap: 8px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
  align-items: center;
}
.header .cta-header--link:hover {
  background-image: linear-gradient(118deg, #fff -15%, #dfe4fd 59%, #dfe4fd 59%);
}
.header--nav_list {
  margin-bottom: 0;
  display: flex;
  gap: 55px;
}
@media (max-width: 1400px) {
  .header--nav_list {
    gap: 36px;
  }
}
.header--nav_item {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.header--nav_item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 20px solid rgba(72, 92, 230, 0.9490196078);
  transform: translate(50%, 0%);
  transition: 0.4s;
  opacity: 0;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 175%);
}
@media (max-width: 1599px) {
  .header--nav_item::after {
    transform: translate(-50%, 35px);
  }
}
.header--nav_item:hover::after {
  opacity: 1;
}
.header--nav_item a {
  font-size: 2rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  transition: 0.4s;
  text-decoration: none;
  color: #F0F1F7 !important;
}
@media (max-width: 1599px) {
  .header--nav_item a {
    font-size: 2rem;
  }
}
.header--nav_item .arrow {
  transition: 0.4s;
}
.header--nav_item:hover a {
  color: #485CE6 !important;
}
.header--nav_item:hover .arrow {
  transform: rotate(-90deg);
}
.header--nav_item:hover .header--nav_dropdown {
  transform: translate(-50%, 100%) scale(1);
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 1599px) {
  .header--nav_item:hover .header--nav_dropdown {
    transform: translate(-40%, 100%) scale(1);
  }
}
.header--nav_itemNot::after {
  display: none;
}
.header--nav_dropdown {
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transform: translate(-50%, 100%);
  padding-top: 45px;
  transition: 0.4s;
  pointer-events: none;
}
.header--nav_dropdown-list {
  width: 865px;
  height: auto;
  padding: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  background-color: rgba(72, 92, 230, 0.95);
  align-items: center;
  gap: 140px;
}
.header--nav_dropdown-item {
  width: 100%;
  display: flex;
}
.header--nav_dropdown-item a {
  padding: 12px 8px;
  width: 100%;
  border-bottom: 1px solid rgba(223, 228, 253, 0.3490196078);
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 2rem;
  line-height: 1;
  color: #F0F1F7 !important;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
  color: #fff !important;
  transition: 0.4s;
  display: grid;
  grid-template-columns: 1fr 24px;
}
.header--nav_dropdown-item a svg {
  opacity: 0;
  transition: all 0.3s ease-in;
}
@media (max-width: 1599px) {
  .header--nav_dropdown-item a {
    font-size: 2rem;
  }
}
.header--nav_dropdown-item a:hover {
  color: #fff !important;
  border-bottom-color: #DFE4FD;
}
.header--nav_dropdown-item a:hover svg {
  opacity: 1;
}
.header--btn-menu {
  display: none;
}
.header--menu-inside {
  display: none;
  opacity: 0;
}
@media (max-width: 1599px) {
  .header {
    padding: 30px 0px;
  }
}
@media (max-width: 1200px) {
  .header .arrow-seach {
    display: none;
  }
  .header--container {
    width: 100%;
    padding: 0 0 0 10vw;
    justify-content: space-between;
    gap: 0px;
    padding-inline: 14px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
  }
  .header--logo {
    position: relative;
    z-index: 3;
  }
  .header--nav {
    display: none;
  }
  .header--btn-menu {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 13px 24px 13px 11px;
    overflow: hidden;
    background: #6DC7FF;
    position: relative;
    box-shadow: 7px 7px 0px #D76FA2;
    z-index: 2;
    cursor: pointer !important;
  }
  .header--btn-menu span {
    width: 20px;
    height: 2px;
    background-color: #F0F1F7;
    transition: 0.4s ease-in-out;
    left: 0;
    display: block;
  }
  .header--btn-menu.open span:nth-child(1) {
    transform: translateY(6px) rotate(135deg);
  }
  .header--btn-menu.open span:nth-child(2) {
    opacity: 0;
  }
  .header--btn-menu.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-135deg);
  }
  .header--menu-inside {
    position: fixed;
    z-index: 1;
    inset: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: black;
    padding: 135px 10vw 0px;
  }
  .header--menu-inside .nav {
    flex: 1;
  }
  .header--menu-inside .nav_list {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .header--menu-inside .nav_item {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  .header--menu-inside .nav_item a {
    font-size: 1.7rem;
    line-height: 1;
    color: #F0F1F7;
    font-weight: 700;
    font-family: "Satoshi";
    text-align: start;
    transition: 0.4s;
  }
}
@media (max-width: 1200px) and (max-width: 1599px) {
  .header--menu-inside .nav_item a {
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_item .arrow {
    transition: 0.4s;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_item:hover a {
    color: #D76FA2;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_item.active a {
    color: #D76FA2;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_dropdown {
    position: absolute;
    left: 0%;
    bottom: 0;
    opacity: 0;
    transform: translateY(100%);
    padding-top: 25px;
    z-index: 100;
    display: none;
    opacity: 0;
  }
  .header--menu-inside .nav_dropdown-list {
    width: 206px;
    height: auto;
    padding: 32px 10px 25px 10px;
    display: flex;
    flex-direction: column;
    gap: 21px;
    position: relative;
    background-color: #111215;
    clip-path: polygon(100% 0, 100% 83%, 83% 100%, 0 100%, 0 0);
  }
  .header--menu-inside .nav_dropdown-list::before {
    content: "";
    width: 100%;
    height: 3px;
    background: transparent linear-gradient(91deg, #13b9ef 0%, #e548ad 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    inset: 0;
  }
  .header--menu-inside .nav_dropdown-item {
    width: 100%;
    display: flex;
  }
  .header--menu-inside .nav_dropdown-item a {
    padding-left: 30px;
    width: 100%;
    font-size: 1.7rem;
    line-height: 1;
    color: #F0F1F7 !important;
    font-weight: 700;
    font-family: "Satoshi";
    text-align: start;
    transition: 0.4s;
  }
}
@media (max-width: 1200px) and (max-width: 1599px) {
  .header--menu-inside .nav_dropdown-item a {
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_dropdown-item a:hover {
    color: #D76FA2 !important;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .social-midias {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    z-index: 1000;
  }
  .header--menu-inside .social-midias svg {
    width: auto;
    max-width: 20px;
    max-height: 20px;
    height: auto;
    object-fit: contain;
    transition: 0.4s;
  }
  .header--menu-inside .social-midias svg path {
    transition: 0.4s;
  }
  .header--menu-inside .social-midias svg:hover {
    fill: #F0F1F7;
  }
  .header--menu-inside .social-midias svg:hover path {
    fill: #F0F1F7;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .details {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-57%, 40%);
    width: 212px;
    height: auto;
    object-fit: contain;
  }
}
@media (max-width: 699px) {
  .header--logo {
    width: 192px;
    object-fit: cover;
  }
}
.header .hamburger {
  display: none;
}
@media (max-width: 1200px) {
  .header .hamburger {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
  }
}
.header .hamburger .close {
  display: none;
}
.header .hamburger .open {
  display: block;
}
.header .hamburger .line {
  width: 50px;
  height: 4.5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header .hamburger:hover {
  cursor: pointer;
}
.header #hamburger-1.is-active .close {
  display: block;
}
.header #hamburger-1.is-active .open {
  display: none;
}
.header .menu-mobile {
  grid-column: span 2;
  display: none;
}
.header .menu-mobile.active {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  max-height: 90vh;
  overflow-y: scroll;
}
.header .menu-mobile--acordion {
  display: flex;
  text-decoration: none;
  font-size: 2.5rem;
  line-height: 1.7;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.header .menu-mobile--acordion:not(:first-child) {
  margin-top: 20px;
}
@media (max-width: 1599px) {
  .header .menu-mobile--acordion {
    font-size: 2rem;
  }
}
.header .menu-mobile--acordionLink {
  display: flex;
  text-decoration: none;
  font-size: 2rem;
  line-height: 1.7;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: center;
  max-width: 644px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.header .menu-mobile--acordionLink a {
  text-decoration: none;
  font-size: 2rem;
  line-height: 1.7;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: center;
}
.header .menu-mobile--acordionItem {
  font-size: 1.8rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
  opacity: 0.6;
  max-width: 644px;
  text-decoration: none;
  margin-top: 25px;
}
.header .menu-mobile--acordionItem a {
  font-size: 1.8rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
}
@media (max-width: 1599px) {
  .header .menu-mobile--acordionItem {
    font-size: 2rem;
  }
}
.header .menu-mobile--acordionBox {
  overflow: hidden;
  height: 0;
  width: 100%;
}
.header .menu-mobile--searchBox {
  margin-top: 30px;
  height: 46px;
  background: #596BE8;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
}
.header .menu-mobile--searchBox input {
  border: none;
  height: 100%;
  outline: none;
  box-shadow: none;
  padding: 20px 12px;
  width: 100%;
  background: transparent;
  transition: all 0.3s ease;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
}
.header .menu-mobile--searchBox input::placeholder {
  font-size: 1.8rem;
  line-height: 3rem;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
}
.header .menu-mobile--searchBox input:focus {
  background: #fff;
  border-radius: 5px 0 0 5px;
}
.header .menu-mobile--searchBox input:focus, .header .menu-mobile--searchBox input:focus::placeholder {
  color: #1E1E1E;
}
.header .menu-mobile--searchBox input:focus ~ button {
  background: #DFE4FD;
  border-radius: 0 5px 5px 0;
}
.header .menu-mobile--searchBox input:focus ~ button svg path {
  fill: #485CE6;
}
.header .menu-mobile--searchBox button {
  padding-inline: 10px;
  max-width: 45px;
  height: 100%;
  transition: all 0.3s ease;
}
.header .search_box {
  background: rgba(72, 92, 230, 0.9490196078);
  padding-inline: 12px;
  padding-top: 12px;
  padding-bottom: 44px;
  position: absolute;
  right: 0;
  top: -22px;
  z-index: 9;
  min-width: 606px;
  max-width: 606px;
  border-radius: 5px;
  display: none;
}
@media (max-width: 1200px) {
  .header .search_box {
    display: none;
  }
}
.header .search_box--input {
  height: 46px;
  width: 100%;
  display: flex;
  margin-bottom: 44px;
}
.header .search_box--input input {
  border: none;
  height: 100%;
  outline: none;
  box-shadow: none;
  padding-left: 32px;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 1.8rem;
  line-height: 1;
  color: #1E1E1E;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
}
.header .search_box--input input::placeholder {
  font-size: 1.8rem;
  line-height: 1;
  color: #1E1E1E;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
}
.header .search_box--input button {
  width: 45px;
  background: #DFE4FD;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .search_box--input button svg {
  font-size: 3rem;
  color: #485CE6;
  width: 24px;
  height: 24px;
}
.header .search_box--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 90px;
  padding-inline: 3.2rem;
}
.header .search_box--buscas {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.header .search_box--recente {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.header .search_box--recente h4 {
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
}
.header .search_box--recente a {
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #fff;
  font-weight: 400;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
}
.header .search_box--popular {
  display: flex;
  flex-direction: column;
}
.header .search_box--popular h4 {
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
  margin-bottom: 12px;
}
.header .search_box--popular a {
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #fff;
  font-weight: 400;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
}
.header .search_box--popular a:not(:last-child) {
  margin-bottom: 8px;
}
.header .search_box--popular a:hover {
  text-decoration: underline;
}
.header .search_box--service {
  display: flex;
  flex-direction: column;
}
.header .search_box--service h4 {
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
  margin-bottom: 12px;
}
.header .search_box--service a {
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #fff;
  font-weight: 400;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.header .search_box--service a:not(:last-child) {
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(223, 228, 253, 0.1490196078);
}
.header .search_box--service a span {
  font-size: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #DFE4FD;
  transition: 0.4s;
}
.header .search_box--service a span svg {
  transition: 0.4s;
}
.header .search_box--service a:hover span {
  color: #fff;
}
.header .search_box--service a:hover span svg {
  transform: translate(8px, 0px);
}
.header .search_box--destaque {
  margin-top: 34px;
  padding-inline: 3.2rem;
}
.header .search_box--destaque h4 {
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
  margin-bottom: 12px;
}
.header .search_box--destaque a {
  font-size: 1.5rem;
  line-height: 1;
  color: #1E1E1E;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  background: #fff;
  border-radius: 5px;
  padding: 20px 30px;
  text-decoration: none;
}

#footer {
  padding-top: 87px;
  background-image: linear-gradient(146deg, #485ce6 32%, #381a8f 123%);
  padding-bottom: 52px;
  position: relative;
}
@media (max-width: 1080px) {
  #footer {
    background-image: linear-gradient(90deg, #485ce6 32%, #381a8f 123%);
    padding-top: 44px;
    padding-bottom: 30px;
  }
}
#footer .space-title {
  margin-top: 70px;
}
#footer .footer-redes {
  display: flex;
  gap: 12px;
  margin-top: 100px;
}
@media (max-width: 1080px) {
  #footer .footer-redes {
    margin-top: 44px;
    justify-content: center;
  }
}
#footer .footer-redes svg {
  transition: all 0.3s ease-in-out;
}
#footer .footer-redes svg path {
  transition: all 0.3s ease-in-out;
}
#footer .footer-redes svg:hover {
  transform: scale(1.2);
}
#footer .footer-redes svg:hover path {
  fill: #000008;
}
#footer .footer--arrowUp {
  position: absolute;
  position: absolute;
  right: 12rem;
  cursor: pointer;
}
@media (max-width: 1650px) {
  #footer .footer--arrowUp {
    right: 1rem;
  }
}
@media (max-width: 1080px) {
  #footer .footer--arrowUp {
    margin: 0 auto;
    margin-bottom: 30px;
    position: relative;
    right: inherit;
    top: inherit;
    display: block;
    width: 50px;
    height: 50px;
  }
}
#footer .footer--logo {
  height: 28px;
  width: 221px;
}
@media (max-width: 1080px) {
  #footer .footer--logo {
    height: 22px;
    width: 176px;
  }
}
#footer .footer--text {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
  opacity: 0.5;
  padding-top: 4.6rem;
}
@media (max-width: 1599px) {
  #footer .footer--text {
    font-size: 1.5rem;
  }
}
@media (max-width: 1080px) {
  #footer .footer--text {
    padding-top: 0;
    text-align: center;
    max-width: 100% !important;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
#footer .footer--title {
  font-size: 2rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  margin-bottom: 26px;
}
@media (max-width: 1080px) {
  #footer .footer--title {
    text-align: center;
    margin-bottom: 30px;
  }
}
#footer .footer--dados {
  margin-top: 30px;
}
#footer .footer--dados p {
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 1.6;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
}
@media (max-width: 1599px) {
  #footer .footer--dados p {
    font-size: 1.5rem;
  }
}
@media (max-width: 1080px) {
  #footer .footer--dados p {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
@media (max-width: 1080px) {
  #footer .footer--dados {
    margin-bottom: 40px;
    text-align: center;
  }
  #footer .footer--dados p {
    text-align: center;
  }
}
#footer .footer--link a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-size: 1.5rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
  text-decoration: none;
  opacity: 0.5;
}
@media (max-width: 1080px) {
  #footer .footer--link a {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
#footer .footer--link:not(:first-child) {
  margin-top: 18px;
}
@media (max-width: 1080px) {
  #footer .footer--link:not(:first-child) {
    margin-top: 0;
  }
}
#footer .footer--link:hover a {
  opacity: 1;
}
@media (max-width: 1080px) {
  #footer .footer--link {
    text-align: center;
  }
  #footer .footer--link a {
    text-align: center;
  }
}
#footer .row-bottom {
  margin-top: 14vh;
}
@media (max-width: 1080px) {
  #footer .row-bottom {
    margin-top: 50px;
  }
}
#footer .footer-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
#footer .footer-bottom span {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #F0F1F7;
  font-weight: 400;
  font-family: "Satoshi";
  text-align: start;
  opacity: 0.5;
}
#footer .footer-bottom--great {
  max-width: 72px;
  max-height: 84px;
  margin-left: 15px;
}
@media (max-width: 1080px) {
  #footer .footer-bottom--great {
    margin-left: 0;
    width: 49.52px;
    height: 84.21px;
  }
}
#footer .footer-bottom--weecom svg {
  transition: 0.4s;
  width: 30px;
}
#footer .footer-bottom--weecom svg path {
  transition: 0.4s;
}
#footer .footer-bottom--weecom svg:hover {
  transform: rotate(360deg);
}
#footer .footer-bottom--weecom svg:hover path {
  fill: #000008;
}
@media (max-width: 1080px) {
  #footer .footer-bottom {
    flex-direction: column;
    margin-top: 48px;
    gap: 48px;
  }
  #footer .footer-bottom span {
    order: 1;
    margin-top: -33px;
  }
  #footer .footer-bottom--weecom {
    order: 2;
  }
  #footer .footer-bottom--great {
    order: 3;
    margin-bottom: 40px;
  }
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.show--error input {
  color: red !important;
  border: 1px solid red !important;
}
.show--error input::placeholder {
  color: red !important;
}
.show--error .error--message {
  display: block;
  color: red !important;
}

.error--message {
  display: none;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #DFE4FD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: #485CE6;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: red;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-size: 2.5rem;
  line-height: 3rem;
  color: #1e1e1e;
  font-weight: 400;
  font-family: "Satoshi";
  text-align: center;
}
.error-message-forms--container .confirm {
  font-size: 1.8rem;
  line-height: 1;
  color: #1e1e1e;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #DFE4FD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: #485CE6;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
  font-size: 4.5rem;
  color: white;
}
.success-message-forms--container .description {
  font-size: 2.5rem;
  line-height: 3rem;
  color: #1e1e1e;
  font-weight: 400;
  font-family: "Satoshi";
  text-align: center;
}
.success-message-forms--container .confirm {
  font-size: 1.8rem;
  line-height: 1;
  color: #1e1e1e;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
    padding: 0;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #F0F1F7;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  clip-path: polygon(0 8%, 72% 8%, 75% 0, 100% 0, 100% 100%, 19% 100%, 16% 92%, 0 92%);
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, 900);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

/*------------------------- Containers -----------------------*/
.termsfeed-com---palette-dark.termsfeed-com---nb {
  background: #485CE6;
  background-color: #485CE6;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  box-shadow: 0px 3px 10px #1E1E1E;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  background-color: white;
}

.termsfeed-com---pc-dialog .cc-pc-head {
  background: #485CE6;
  color: #fff;
  border: none;
}
.termsfeed-com---pc-dialog .cc-pc-head .cc-pc-head-close:focus {
  border: 2px solid #fff;
}

.termsfeed-com---palette-dark .cc-cp-body,
.termsfeed-com---palette-dark .cc-cp-body-tabs,
.termsfeed-com---palette-dark .cc-cp-body-content,
.termsfeed-com---palette-dark .cc-cp-foot {
  background: #485CE6 !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item {
  background: #485CE6 !important;
  border: none !important;
  color: #fff !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item[active=true] {
  background: linear-gradient(146deg, #485ce6 32%, #381a8f 123%) !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link {
  color: #fff !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link:focus {
  box-shadow: none !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs {
  border-right: 1px solid #fff;
}
@media (max-width: 600px) {
  .termsfeed-com---palette-dark .cc-cp-body-tabs {
    border-right: none;
    border-bottom: 1px solid #fff;
  }
}
.termsfeed-com---palette-dark .cc-cp-foot {
  border-color: #fff !important;
}
.termsfeed-com---palette-dark .cc-cp-foot-byline a {
  color: #fff !important;
  font-weight: bold;
}
.termsfeed-com---palette-dark .cc-cp-foot-save {
  color: #fff;
  background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
  margin-right: 1rem !important;
  border: 1px solid #fff;
}

/*-------------------------TEXTS -----------------------*/
/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree {
  background: #1E1E1E;
  color: #fff;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-dark .cc-nb-okagree:hover {
  background-color: #485CE6;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #fff;
  background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border: 1px solid #fff;
}
.termsfeed-com---palette-dark .cc-nb-reject:hover {
  background-color: #485CE6;
}

.termsfeed-com---palette-dark .cc-nb-changep {
  color: #fff;
  background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border: 1px solid #fff;
}

.termsfeed-com---palette-dark .cc-nb-okagree {
  color: #fff;
  background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border: 1px solid #fff;
}

.termsfeed-com---nb-simple {
  bottom: 2vh;
  border: 0.2px solid #485CE6;
  transition: 0.4s;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.termsfeed-com---nb-simple::after {
  content: "";
  height: 3px;
  background-color: #485CE6;
  position: absolute;
  left: 0;
  bottom: 0;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-reject {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-changep {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

* {
  word-break: inherit !important;
  overflow-wrap: anywhere !important;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

b, strong {
  color: #485ce6 !important;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Variable.woff2") format("woff2"), url("../fonts/Satoshi-Variable.woff") format("woff"), url("../fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-VariableItalic.woff2") format("woff2"), url("../fonts/Satoshi-VariableItalic.woff") format("woff"), url("../fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Light.woff2") format("woff2"), url("../fonts/Satoshi-Light.woff") format("woff"), url("../fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-LightItalic.woff2") format("woff2"), url("../fonts/Satoshi-LightItalic.woff") format("woff"), url("../fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Regular.woff2") format("woff2"), url("../fonts/Satoshi-Regular.woff") format("woff"), url("../fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Italic.woff2") format("woff2"), url("../fonts/Satoshi-Italic.woff") format("woff"), url("../fonts/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Medium.woff2") format("woff2"), url("../fonts/Satoshi-Medium.woff") format("woff"), url("../fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-MediumItalic.woff2") format("woff2"), url("../fonts/Satoshi-MediumItalic.woff") format("woff"), url("../fonts/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Bold.woff2") format("woff2"), url("../fonts/Satoshi-Bold.woff") format("woff"), url("../fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-BoldItalic.woff2") format("woff2"), url("../fonts/Satoshi-BoldItalic.woff") format("woff"), url("../fonts/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Black.woff2") format("woff2"), url("../fonts/Satoshi-Black.woff") format("woff"), url("../fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-BlackItalic.woff2") format("woff2"), url("../fonts/Satoshi-BlackItalic.woff") format("woff"), url("../fonts/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
html {
  font-size: 62.5%;
}
@media (min-width: 700px) and (max-width: 1599px) {
  html {
    font-size: 62.5%;
  }
}

strong {
  color: #485ce6 !important;
}

.form-group {
  position: relative;
  margin: 0;
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

.reveal-text {
  visibility: hidden;
}

.line {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: start;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

#page404 {
  min-height: 100dvh;
  position: relative;
  display: flex;
  align-items: center;
  padding-block: 120px 40px;
}
#page404 .page404--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
#page404 .page404--number {
  font-size: 20rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  padding-left: 80px;
}
@media (max-width: 1080px) {
  #page404 .page404--number {
    padding-left: 0;
    text-align: center;
    font-size: 15rem;
  }
}
#page404 .page404--number2 {
  transform: translate(0px, -48px);
}
@media (max-width: 1080px) {
  #page404 .page404--number2 {
    transform: translate(0px, 0px);
  }
}
#page404 .page404--title {
  font-size: 3.2rem;
  line-height: 1;
  color: #F0F1F7;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: start;
  max-width: 70%;
  margin-bottom: 32px;
}
@media (max-width: 1080px) {
  #page404 .page404--title {
    max-width: 100%;
    text-align: center;
    margin-top: 60px;
  }
}
#page404 .page404--text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Satoshi";
  text-align: start;
  max-width: 86%;
}
@media (max-width: 1080px) {
  #page404 .page404--text {
    max-width: 100%;
    text-align: center;
  }
}
#page404 .page404--link {
  font-size: 2rem;
  line-height: 1rem;
  color: #F0F1F7;
  font-weight: 700;
  font-family: "Satoshi";
  text-align: center;
  text-decoration: none;
  padding: 30px 80px;
  width: fit-content;
  border-radius: 5px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
  background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;
}
#page404 .page404--link:hover {
  background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
}
@media (max-width: 1080px) {
  #page404 .page404--link {
    margin: 0 auto;
    margin-top: 60px;
  }
}