#footer{
    padding-top: 87px;
    background-image: linear-gradient(146deg, #485ce6 32%, #381a8f 123%);
    padding-bottom: 52px;
    position: relative;
    @media (max-width: 1080px) {
        background-image: linear-gradient(90deg, #485ce6 32%, #381a8f 123%);
        padding-top: 44px;
        padding-bottom: 30px;
    }
    .space-title{
        margin-top: 70px;
    }
    .footer-redes{
        display: flex;
        gap: 12px;
        margin-top: 100px;
        @media (max-width: 1080px) {
            margin-top: 44px;
            justify-content: center;
        }
        svg{
            transition: all .3s ease-in-out;
            path{
                transition: all .3s ease-in-out;
            }
            &:hover{
                transform: scale(1.2);
                path{
                    fill: #000008;
                }
            }
        }
    }
    .footer--arrowUp{
        position: absolute;
        position: absolute;
        right: 12rem;
        cursor: pointer;
        @media (max-width: 1650px) {
            right: 1rem;
        }
        @media (max-width: 1080px) {
            margin: 0 auto;
            margin-bottom: 30px;
            position: relative;
            right: inherit;
            top: inherit;
            display: block;
            width: 50px;
            height: 50px;
        }
    }
    .footer{
        &--logo{
            height: 28px;
            width: 221px;
            @media (max-width: 1080px) {
                height: 22px;
                width: 176px;
            }
        }
        &--text{
            @include fontTemplate(1.5rem, 1.6, #F0F1F7, $medium, $Satoshi);
            opacity: .5;
            padding-top: 4.6rem;
            @media (max-width: 1599px) {
                font-size: 1.5rem;
            }
            @media (max-width: 1080px) {
                padding-top: 0;
                text-align: center;
                max-width: 100% !important;
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
        &--title{
            @include fontTemplate(2rem, 1, #F0F1F7, $bold, $Satoshi);
            margin-bottom: 26px;
            @media (max-width: 1080px) {
                text-align: center;
                margin-bottom: 30px;
            }
        }
        &--dados{
            margin-top: 30px;
            p{
                text-decoration: none;
                @include fontTemplate(1.5rem, 1.6, #F0F1F7, $medium, $Satoshi);
                @media (max-width: 1599px) {
                    font-size: 1.5rem;
                }
                @media (max-width: 1080px) {
                    text-align: center;
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
            }
            @media (max-width: 1080px) {
                margin-bottom: 40px;
                text-align: center;
                p{
                    text-align: center;
                }
            }
        }
        &--link{
            a{
                text-decoration: none;
                transition: all .3s ease-in-out;
                @include fontTemplate(1.5rem, 1, #F0F1F7, $medium, $Satoshi);
                @media (max-width: 1080px) {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                text-decoration: none;
                opacity: .5;
            }
            &:not(:first-child){
                margin-top: 18px;
                @media (max-width: 1080px) {
                    margin-top: 0;
                }
            }
            &:hover{
                a{
                    opacity: 1;
                }
            }
            @media (max-width: 1080px) {
                text-align: center;
                a{
                    text-align: center;
                }
            }
        }
    }
    .row-bottom{
        margin-top: 14vh;
        @media (max-width: 1080px) {
            margin-top: 50px;
        }
    }
    .footer-bottom{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        span{
            @include fontTemplate(1.5rem, 1.6, #F0F1F7, $regular, $Satoshi);
            opacity: .5;
        }
        &--great{
            max-width: 72px;
            max-height: 84px;
            margin-left: 15px;
            @media (max-width: 1080px) {
                margin-left: 0;
                width: 49.52px;
                height: 84.21px;
            }
        }
        &--weecom{
            svg {
                transition: $transition;
                width: 30px;
                path {
                    transition: $transition;
                }

                &:hover {
                    transform: rotate(360deg);
                    path {
                        fill: #000008;
                    }
                }
            }
        }
        @media (max-width: 1080px) {
            flex-direction: column;
            margin-top: 48px;
            gap: 48px;
            span{
                order: 1;
                margin-top: -33px;
            }
            &--weecom{
                order: 2;
            }
            &--great{
                order: 3;
                margin-bottom: 40px;
            }
        }
    }
}
