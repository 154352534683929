.header {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0px;
    background: transparent linear-gradient(180deg, #000000 0%, #01050f00 100%)
        0% 0% no-repeat padding-box;
    z-index: 1000;
    height: fit-content;
    transition: 1s;
    &.active {
        background: #485CE6 !important;
        left: inherit;
        right: 0;
        width: calc(100% - 25px);
        padding-bottom: 42px;
        .header--logo{
            filter: brightness(0) invert(1);
        }
    }
    &.activeDown{
        background: #000008;
    }
    .arrow-seach{
        cursor: pointer;
        img{
            width: 20px;
            height: 20px;
            object-fit: fill;
        }
    }
    &--container {
        display: flex;
        align-items: center;
        gap: 50px;
        justify-content: space-between;
    }

    &--logo {
        width: 192px;
        height: 24px;
        object-fit: contain;
    }
    .wrapper-dropdown{
        display: flex;
        flex-direction: column;
        gap: 21px;
    }
    .service-list{
        max-height: 80vh;
        min-width: 865px;
        gap: 100px;
        grid-template-columns: 300px 1fr;
        width: auto;
        @media (max-height: 680px) {
            padding: 25px;
            .service-wrapper{
                gap: 5px;
                li{
                    a{
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
    .service-dropdown{
        @media (max-width: 1500px) {
            &:hover{
                //transform: translate(-33%, 100%)  !important;
            }
        }
    }
    .contato-dropdown{
        @media (max-width: 1500px) {
            &:hover{
                transform: translate(-82%, 100%)  !important;
            }
        }
    }
    .service-wrapper{
        display: grid;
        grid-template-columns: 300px 300px;
        gap: 20px;
    }
    .wrapper-dropdonw{
        li{
            a{
                color: #fff !important;
            }
        }
    }
    .cta-header{
        display: flex;
        flex-direction: column;
        &--title{
            @include fontTemplate(4.8rem, 1, $white, $bold, $Satoshi, start);
        }
        &--text{
            @include fontTemplate(1.6rem, 1, $white, $medium, $Satoshi, start);
            margin-bottom: 0;
            margin-top: 20px;
            @media (max-width: 1599px) {
                font-size: 2rem;
            }
        }
        &--link{
            @include fontTemplate(2rem, 1, #485CE6 !important, $bold, $Satoshi, center);
            text-decoration: none;
            padding: 20px 40px;
            margin-top: 44px;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            gap: 8px;
            border-radius: 5px;
            transition: all 0.3s ease-in;
            align-items: center;
            &:hover{
                background-image: linear-gradient(118deg, #fff -15%, #dfe4fd 59%, #dfe4fd 59%);
            }
        }
    }
    &--nav {
        &_list {
            margin-bottom: 0;
            display: flex;
            gap: 55px;
            @media (max-width: 1400px) {
                gap: 36px;
            }
        }

        &_item {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-left: 25px solid transparent;
                border-right: 25px solid transparent;
                border-bottom: 20px solid #485ce6f2;
                transform: translate(50% , 0%);
                transition: 0.4s;
                opacity: 0;
                pointer-events: none;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 175%);
                @media (max-width: 1599px) {
                    transform: translate(-50%, 35px);

                }
            }
            &:hover{
                &::after{
                    opacity: 1;
                }
            }
            a {
                @include fontTemplate(2rem, 1, $white, $bold, $Satoshi);
                transition: $transition;
                text-decoration: none;
                color: #F0F1F7 !important;
                @media (max-width: 1599px) {
                    font-size: 2rem;
                }
            }

            .arrow {
                transition: $transition;
            }

            &:hover {
                a {
                    color: $blue500 !important;
                }

                .arrow {
                    transform:  rotate(-90deg);
                }

                .header--nav_dropdown {
                    transform: translate(-50%, 100%) scale(1);
                    opacity: 1;
                    pointer-events: all;
                    @media (max-width: 1599px) {
                        transform: translate(-40%, 100%) scale(1);
                    }
                }
            }

        }
        &_itemNot{
            &::after{
                display: none;
            }
        }
        &_dropdown {
            position: absolute;
            left: 50%;
            bottom: 0;
            opacity: 0;
            transform: translate(-50%, 100%) ;
            padding-top: 45px;
            transition: $transition;
            pointer-events: none;
            &-list {
                width: 865px;
                height: auto;
                padding: 60px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                position: relative;
                background-color: rgba(72, 92, 230, 0.95);
                align-items: center;
                gap: 140px;
            }

            &-item {
                width: 100%;
                display: flex;
                a {
                    padding: 12px 8px;
                    width: 100%;
                    border-bottom: 1px solid #dfe4fd59;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    @include fontTemplate(
                        2rem,
                        1,
                        $white !important,
                        $medium,
                        $Satoshi
                    );
                    color: #fff !important;
                    transition: $transition;
                    display: grid;
                    grid-template-columns: 1fr 24px;
                    svg{
                        opacity: 0;
                        transition: all 0.3s ease-in;
                    }
                    @media (max-width: 1599px) {
                        font-size: 2rem;
                    }
                    &:hover {
                        svg{
                            opacity: 1;
                        }
                        color: #fff !important;
                        border-bottom-color: #DFE4FD;
                    }
                }
            }
        }
    }

    &--btn-menu {
        display: none;
    }

    &--menu-inside {
        display: none;
        opacity: 0;
    }

    @media (max-width: 1599px) {
        padding: 30px 0px;
    }

    @media (max-width: 1200px) {
        .arrow-seach{
            display: none;
        }
        &--container {
            width: 100%;
            padding: 0 0 0 10vw;
            justify-content: space-between;
            gap: 0px;
            padding-inline: 14px !important;
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: stretch;
        }

        &--logo {
            position: relative;
            z-index: 3;
        }

        &--nav {
            display: none;
        }

        &--btn-menu {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 13px 24px 13px 11px;
            overflow: hidden;
            background: $blue300;
            position: relative;
            box-shadow: 7px 7px 0px $pink400;
            z-index: 2;
            cursor: pointer !important;

            span {
                width: 20px;
                height: 2px;
                background-color: $white;
                transition: 0.4s ease-in-out;
                left: 0;
                display: block;
            }

            &.open span:nth-child(1) {
                transform: translateY(6px) rotate(135deg);
            }
            &.open span:nth-child(2) {
                opacity: 0;
            }
            &.open span:nth-child(3) {
                transform: translateY(-8px) rotate(-135deg);
            }
        }

        &--menu-inside {
            position: fixed;
            z-index: 1;
            inset: 0;
            width: 100%;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            background-color: black;
            padding: 135px 10vw 0px;

            .nav {
                flex: 1;
                &_list {
                    display: flex;
                    flex-direction: column;
                    gap: 35px;
                }

                &_item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    position: relative;
                    a {
                        @include fontTemplate(
                            1.7rem,
                            1,
                            $white,
                            $bold,
                            $Satoshi
                        );
                        transition: $transition;
                        @media (max-width: 1599px) {
                            font-size: 2rem;
                        }
                    }

                    .arrow {
                        transition: $transition;
                    }

                    &:hover {
                        a {
                            color: $pink400;
                        }
                    }

                    &.active {
                        a {
                            color: $pink400;
                        }
                    }
                }

                &_dropdown {
                    position: absolute;
                    left: 0%;
                    bottom: 0;
                    opacity: 0;
                    transform: translateY(100%);
                    padding-top: 25px;
                    z-index: 100;

                    display: none;
                    opacity: 0;

                    &-list {
                        width: 206px;
                        height: auto;
                        padding: 32px 10px 25px 10px;

                        display: flex;
                        flex-direction: column;
                        gap: 21px;

                        position: relative;
                        background-color: $black500;
                        clip-path: polygon(
                            100% 0,
                            100% 83%,
                            83% 100%,
                            0 100%,
                            0 0
                        );

                        &::before {
                            content: "";
                            width: 100%;
                            height: 3px;
                            background: transparent
                                linear-gradient(91deg, #13b9ef 0%, #e548ad 100%)
                                0% 0% no-repeat padding-box;
                            position: absolute;
                            inset: 0;
                        }
                    }

                    &-item {
                        width: 100%;
                        display: flex;
                        a {
                            padding-left: 30px;
                            width: 100%;
                            @include fontTemplate(
                                1.7rem,
                                1,
                                $white !important,
                                $bold,
                                $Satoshi
                            );
                            transition: $transition;
                            @media (max-width: 1599px) {
                                font-size: 2rem;
                            }
                            &:hover {
                                color: $pink400 !important;
                            }
                        }
                    }
                }
            }

            .social-midias {
                position: absolute;
                bottom: 50px;
                left: 50%;
                transform: translateX(-50%);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                z-index: 1000;

                svg {
                    width: auto;
                    max-width: 20px;
                    max-height: 20px;
                    height: auto;
                    object-fit: contain;
                    transition: $transition;
                    path {
                        transition: $transition;
                    }

                    &:hover {
                        fill: $white;
                        path {
                            fill: $white;
                        }
                    }
                }
            }

            .details {
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translate(-57%, 40%);
                width: 212px;
                height: auto;
                object-fit: contain;
            }
        }
    }

    @media (max-width: 699px) {
        &--logo {
            width: 192px;
            object-fit: cover;
        }
    }


    .hamburger{
        display: none;
        @media (max-width: 1200px) {
            display: flex;
            justify-content: flex-end;
            padding-right: 1rem;
        }
        .close{
            display: none;
        }
        .open{
            display: block;
        }
    }
    .hamburger .line{
        width: 50px;
        height: 4.5px;
        background-color: #ecf0f1;
        display: block;
        margin: 8px auto;
        border-radius: 15px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      .hamburger:hover{
        cursor: pointer;
      }

      /* ONE */




        #hamburger-1.is-active{
            .close{
                display: block;
            }
            .open{
                display: none;
            }
        }




      .menu-mobile{
        grid-column: span 2;
        display: none;
        &.active{
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-x: hidden;
            max-height: 90vh;
            overflow-y: scroll;
        }
            &--acordion{
                display: flex;
                text-decoration: none;
                @include fontTemplate(2.5rem, 1.7, $white, $medium, $Satoshi, center);
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-top: 50px;
                &:not(:first-child){
                    margin-top: 20px;
                }
                @media (max-width: 1599px) {
                    font-size: 2rem;
                }
            }
            &--acordionLink{
                display: flex;
                text-decoration: none;
                @include fontTemplate(2rem, 1.7, $white, $medium, $Satoshi, center);
                max-width: 644px;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                a{
                    text-decoration: none;
                    @include fontTemplate(2rem, 1.7, $white, $medium, $Satoshi, center);

                }
            }
            &--acordionItem{
                @include fontTemplate(1.8rem, 1, #F0F1F7, $medium, $Satoshi, start);
                opacity: .6;
                max-width: 644px;
                text-decoration: none;
                margin-top: 25px;
                a{
                    @include fontTemplate(1.8rem, 1, #F0F1F7, $medium, $Satoshi, start);
                    text-decoration: none;
                }
                @media (max-width: 1599px) {
                    font-size: 2rem;
                }
            }
            &--acordionBox{
                  overflow: hidden;
                  height: 0;
                  width: 100%;
            }
            &--searchBox{
                margin-top: 30px;
                height: 46px;
                background: #596BE8;
                border-radius: 5px;
                width: 100%;
                display: flex;
                align-items: center;
                input{
                    border: none;
                    height: 100%;
                    outline: none;
                    box-shadow: none;
                    padding: 20px 12px;
                    width: 100%;
                    background: transparent;
                    transition: all .3s ease;
                    @include fontTemplate(1.8rem, 3rem, $white, $medium, $Satoshi, start);
                    &::placeholder{
                        @include fontTemplate(1.8rem, 3rem, $white, $medium, $Satoshi, start);
                    }
                    &:focus {
                        background: #fff;
                        border-radius: 5px 0 0 5px;

                        &, &::placeholder {
                            color: #1E1E1E;
                        }

                        & ~ button {
                            background: #DFE4FD;
                            border-radius: 0 5px 5px 0;

                            svg {
                                path { fill: #485CE6; }
                            }
                        }
                    }
                }
                button {
                    padding-inline: 10px;
                    max-width: 45px;
                    height: 100%;
                    transition: all .3s ease;
                }
            }
      }


      .search_box{
        background: #485CE6F2;
        padding-inline: 12px;
        padding-top: 12px;
        padding-bottom: 44px;
        position: absolute;
        right: 0;
        top: -22px;
        z-index: 9;
        min-width: 606px;
        max-width: 606px;
        border-radius: 5px;
        display: none;
        @media (max-width: 1200px) {
            display: none;
        }
        &--input{
            height: 46px;
            width: 100%;
            display: flex;
            margin-bottom: 44px;
            input{
                border: none;
                height: 100%;
                outline: none;
                box-shadow: none;
                padding-left: 32px;
                width: 100%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                @include fontTemplate(1.8rem, 1, #1E1E1E, $medium, $Satoshi, start);
                &::placeholder{
                    @include fontTemplate(1.8rem, 1, #1E1E1E, $medium, $Satoshi, start);
                }
            }
            button{
                width: 45px;
                background: #DFE4FD;
                height: 100%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    font-size: 3rem;
                    color: #485CE6;
                    width: 24px;
                    height: 24px;
                }
            }
        }
        &--grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 90px;
            padding-inline: 3.2rem;
        }
        &--buscas{
            display: flex;
            flex-direction: column;
            gap: 35px;
        }
        &--recente{
            display: flex;
            flex-direction: column;
            gap: 12px;
            h4{
                @include fontTemplate(1.8rem, 1, #fff, $bold, $Satoshi, start);
                text-decoration: none;
            }
            a{
                @include fontTemplate(1.5rem, 2.5rem, #fff, 400, $Satoshi, start);
                text-decoration: none;
            }
        }
        &--popular{
            display: flex;
            flex-direction: column;
            h4{
                @include fontTemplate(1.8rem, 1, #fff, $bold, $Satoshi, start);
                text-decoration: none;
                margin-bottom: 12px;
            }
            a{
                &:not(:last-child){
                    margin-bottom: 8px;
                }
                @include fontTemplate(1.5rem, 2.5rem, #fff, 400, $Satoshi, start);
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &--service{
            display: flex;
            flex-direction: column;
            h4{
                @include fontTemplate(1.8rem, 1, #fff, $bold, $Satoshi, start);
                text-decoration: none;
                margin-bottom: 12px;
            }
            a{
                &:not(:last-child){
                    margin-bottom: 8px;
                    border-bottom: 1px solid #DFE4FD26;
                }
                @include fontTemplate(1.5rem, 2.5rem, #fff, 400, $Satoshi, start);
                text-decoration: none;
                display: flex;
                flex-direction: column;
                gap: 5px;
                span{
                    font-size: 12px;
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    color: #DFE4FD;
                    transition: $transition;
                    svg{
                        transition: $transition;
                    }
                }
                &:hover{
                   span{
                    color: #fff;
                       svg{
                        transform: translate(8px, 0px);
                       }
                   }
                }
            }
        }
        &--destaque{
            margin-top: 34px;
            padding-inline: 3.2rem;
            h4{
                @include fontTemplate(1.8rem, 1, #fff, $bold, $Satoshi, start);
                text-decoration: none;
                margin-bottom: 12px;
            }
            a{
                @include fontTemplate(1.5rem, 1, #1E1E1E, $bold, $Satoshi, start);
                background: #fff;
                border-radius: 5px;
                padding: 20px 30px;
                text-decoration: none;
            }
        }

      }

}
