$accentColor: #485CE6;
$black: #1E1E1E;
$neutral: #5B5B5B;

/*------------------------- Containers -----------------------*/

.termsfeed-com---nb .cc-nb-main-container{

}

.termsfeed-com---palette-dark.termsfeed-com---nb{
    background: #485CE6;
    background-color: #485CE6;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    box-shadow: 0px 3px 10px #1E1E1E;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    background-color: white;
}


.termsfeed-com---pc-dialog {
    .cc-pc-head {
        background: #485CE6;
        color: #fff;
        border: none;

        .cc-pc-head-close {
            &:focus {
                border: 2px solid #fff;
            }
        }
    }
}
.termsfeed-com---palette-dark {
    .cc-cp-body,
    .cc-cp-body-tabs,
    .cc-cp-body-content,
    .cc-cp-foot {
        background: #485CE6 !important;
    }
    .cc-cp-body-tabs-item {
        background: #485CE6 !important;
        border: none !important;
        color: #fff !important;
        
        &[active=true] {
            background: linear-gradient(146deg,#485ce6 32%,#381a8f 123%) !important;
        }
        &-link {
            color: #fff !important;

            &:focus {
                box-shadow: none !important;
            }
        }
    }
    .cc-cp-body-tabs {
        border-right: 1px solid #fff;

        @media (max-width: 600px) {
            border-right: none;
            border-bottom: 1px solid #fff;
        }
    }
    .cc-cp-foot {
        border-color: #fff !important;

        &-byline {
            a { 
                color: #fff !important;
                font-weight: bold;
            }
        }
        &-save {
            color: #fff;
            background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
            margin-right: 1rem !important;
            border: 1px solid #fff;            
        }
    }
}

/*-------------------------TEXTS -----------------------*/


/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree{
    background: #1E1E1E;
    color: #fff;
    margin-right: 1rem !important;
    transition: background-color 1s;
    &:hover{
        background-color: #485CE6;
    }
}
.termsfeed-com---palette-dark .cc-nb-reject{
    color: #fff;
    background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border: 1px solid #fff;
    &:hover{
        background-color: #485CE6;
    }
}
.termsfeed-com---palette-dark .cc-nb-changep{
    color: #fff;
    background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border: 1px solid #fff;
}
.termsfeed-com---palette-dark .cc-nb-okagree{
    color: #fff;
    background: linear-gradient(141deg, #485CE6 31.89%, transparent) #485CE6 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border: 1px solid #fff;
}
.termsfeed-com---nb-simple{
    bottom: 2vh;
    border: 0.2px solid #485CE6;
    transition: 0.4s;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    &::after{
    content: "";
    height: 3px;
    background-color: #485CE6;
    position: absolute;
    left: 0;
    bottom: 0;
    }
}
// Accept
.termsfeed-com---palette-light .cc-nb-okagree{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
// Decline
.termsfeed-com---palette-light .cc-nb-reject{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
// Config
.termsfeed-com---palette-light .cc-nb-changep{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
