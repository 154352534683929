.loading-forms {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    display: none;
    opacity: 1;
    &.open-modal {
        display: flex;
    }

    &--container {
        position: absolute;
        transform: translateY(-65%) translateX(-50%);
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        top: 50%;
        left: 50%;

        width: 30vw;
        height: 30vh;
        clip-path: polygon(
            0 8%,
            72% 8%,
            75% 0,
            100% 0,
            100% 100%,
            19% 100%,
            16% 92%,
            0 92%
        );
    }

    .custom-loader {
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        background: conic-gradient(#0000 10%, $black);
        -webkit-mask: radial-gradient(
            farthest-side,
            #0000 calc(100% - 8px),
            #000 0
        );
        animation: s3 1s infinite linear;
    }

    @media (max-width: 799px) {
        &--container {
            width: 95%;
            padding: 0 !important;
            height: 200px;
        }

        .custom-loader {
            width: 40px;
            height: 40px;
        }
    }
}
