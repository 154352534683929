.show--error {
    input {
        color: red !important;
        border: 1px solid red !important;

        &::placeholder {
            color: red !important;
        }
    }
    .error--message {
        display: block;
        color: red !important;
    }
}
.error--message {
    display: none;
}
.error-message-forms {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    display: none;
    opacity: 1;
    &.open-modal {
        display: flex;
    }

    &--container {
        position: absolute;
        transform: translateY(-65%) translateX(-50%);
        background-color: #DFE4FD;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        top: 50%;
        left: 50%;
        width: 450px;
        padding: 30px 50px;
        gap: 20px;

        #btn--close-error-message {
            position: absolute;
            top: 2vw;
            right: 2vw;

            ion-icon {
                font-size: 3.4rem;
                color: #485CE6;
            }
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: red;
            font-size: 4.5rem;
            color: white;
        }

        .description {
            @include fontTemplate(
                2.5rem,
                3rem,
                #1e1e1e,
                $regular,
                $Satoshi,
                center
            );
        }

        .confirm {
            @include fontTemplate(1.8rem, 1, #1e1e1e, $bold, $Satoshi, center);
            padding: 20px 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;

            @media (max-width: 799px) {
                margin-top: 40px;
            }
        }
    }

    @media (max-width: 799px) {
        &--container {
            width: 95%;
            padding-inline: 20px;
            padding-top: 40px;
            padding-bottom: 40px;
            gap: 30px;

            #btn--close-error-message {
                img {
                    width: 20px;
                }
            }

            .icon {
                width: 65px;
                height: 65px;
            }

            .description {
                font-size: 1.4rem;
                line-height: 20px;
            }

            .confirm {
                margin-top: 0px;
                width: 150px;
                height: 50px;
            }
        }
    }
}
