@import '../general/reset';
@import '../general/media-screen';
@import '../general/variable';
@import '../general/color';
@import '../general/fonts';
@import '../general/mixins';
@import '../general/common';

#page404{
    min-height: 100dvh;
    position: relative;
    display: flex;
    align-items: center;
    padding-block: 120px 40px;
    .page404{
        &--bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
        &--number{
          @include fontTemplate(20rem, 1, #F0F1F7, $bold, $Satoshi, start);
           padding-left: 80px;
            @media (max-width: 1080px) {
                padding-left: 0;
                text-align: center;
                font-size: 15rem;
            }
        }
        &--number2{
            transform: translate(0px, -48px);
            @media (max-width: 1080px) {
                transform: translate(0px, 0px);
            }
        }

        &--title{
            @include fontTemplate(3.2rem, 1, #F0F1F7, $bold, $Satoshi, start);
            max-width: 70%;
            margin-bottom: 32px;
            @media (max-width: 1080px) {
                max-width: 100%;
                text-align: center;
                margin-top: 60px;
            }
        }
        &--text{
            @include fontTemplate(1.5rem, 2.5rem, #F0F1F7, $medium, $Satoshi, start);
            max-width: 86%;
            @media (max-width: 1080px) {
                max-width: 100%;
                text-align: center;
            }
        }
        &--link{
            @include fontTemplate(2rem, 1rem, #F0F1F7, $bold, $Satoshi, center);
            text-decoration: none;
            padding: 30px 80px;
            width: fit-content;
            border-radius: 5px;
            margin-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease-in;
            background: linear-gradient(136deg, #485ce6 31.89%, transparent) #485ce6 34%;
            &:hover{
                background: linear-gradient(136deg, #485ce6 31.89%, transparent) #381a8f 34%;
            }
            @media (max-width: 1080px) {
                margin: 0 auto;
                margin-top: 60px;
            }
        }
    }
}
